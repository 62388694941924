import "@formatjs/intl-getcanonicallocales/polyfill";
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en"; // locale-data for en
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en"; // locale-data for en
// the above are polyfills for older versions of Safari
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Section, { BgEffectX, Heading3, RichText } from "./elements/Section";
import {
	container,
	grid,
	gridc,
	spacingRestricted,
	screen,
} from "../styles/mixins";
import color, { themeColors } from "../styles/colors";
import HowLongSlider from "./HowLongSlider";
import LoanAmountSlider from "./LoanAmountSlider";
import {
	h2,
	h4,
	h5,
	h6,
	pSmall,
	meta,
	calculatorValue,
	h3,
} from "../styles/typography";
import Button from "./elements/Button";
import { button } from "../styles/typography";
import { formatValue } from "react-currency-input-field";
import Disclaimer from "./Disclaimer";
import { useScreenQuery } from "../libs/useMediaQuery";
import Tracker from "../scripts/calculatorTracking";

const SectionEl = styled(Section)`
  padding-top: 0 !important;
  padding-bottom: 50px !important;

	@media ${screen("md")} {
		padding-bottom: 100px !important;
	}
`

const ContainerEl = styled.div`
	${container()}
`;
const ContentWrap = styled.div`
	${gridc(3, 44)}

	@media ${screen("md")} {
		${gridc(3, 44)}
	}
`;

const CalculatorWrap = styled.div`
	border: 1px solid #fcf5d2;
	box-sizing: border-box;
	border-radius: 15px;
	background-color: ${(props) => themeColors[props.colorTheme].accent2};
	border: 3px solid ${(props) => themeColors[props.colorTheme].bg};
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	${grid(24)}
	${spacingRestricted("padding-bottom", "ml")}
	${spacingRestricted("padding-top", "ml")}

	@media ${screen("lg")} {
		${grid(30)}

		&:after {
			content: "";
			position: absolute;
			left: 50%;
			top: 0;
			height: 100%;
			width: 2px;
			background-color: ${color("purple")};
			margin-left: -1px;
		}
	}

	${spacingRestricted("padding-bottom", "ml")}
	${spacingRestricted("padding-top", "ml")}
`;
const Spacing = styled.div`
	width: 100%;
	${spacingRestricted("height", "m")}
`;

const Calculator = styled.div`
	${gridc(3, 20)}

	@media ${screen("lg")} {
		${gridc(3, 11)}

		margin-bottom: 0;
	}
`;

const Result = styled.div`
	${gridc(3, 20)}

	@media ${screen("lg")} {
		${gridc(-14, 11)}
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		${spacingRestricted("padding-bottom", "s")}
	}
`;

const ResultWrap = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	${spacingRestricted("margin-bottom", "m")}
	margin-left: -1em;
	margin-right: -1em;

	@media ${screen("ms")} {
		margin-left: -3em;
		margin-right: -3em;
	}

	&:after {
		content: "";
		top: 0;
		left: 50%;
		width: 1px;
		height: 100%;
		position: absolute;
		background-color: ${color("light-grey")};
	}
`;

const SingleResult = styled.div`
	width: 50%;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
`;

const H6 = styled.h3`
	${h6}
	${spacingRestricted("margin-bottom", "m")}
	text-align: center;

	@media ${screen("lg")} {
		width: 100%;
	}
`;

const P = styled.p`
	${pSmall}
`;

const MainHeading = styled.h3`
	font-size: 40px;
	line-height: 1.1;
	font-weight: 600;
	text-align: left;
	margin-bottom: 25px;

	@media ${screen("lg")} {
		font-weight: 500;
		letter-spacing: -0.01em;
		font-size: 45px;
	}
`;

const Dollar = styled.span`
	align-self: flex-start;
	${h4}
	display: inline-block;

	@media ${screen("lg")} {
		${h3}
	}
`;

const Period = styled.span`
	align-self: flex-end;
	${h5}
	display: inline-block;
`;

const NumberSpan = styled.span`
	${calculatorValue}
	display: inline-block;
	margin-top: -0.2rem;
	transform: translateY(0.1rem);

	@media ${screen("lg")} {
		transform: translateY(0.35rem);
		margin-top: -0.75rem;
	}

	@media ${screen("ms")} {
		margin-top: -1.4rem;
	}
`;

const Value = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	${spacingRestricted("margin-bottom", "xs")}
`;

const ValueTotal = styled(P)`
	opacity: 0.6;

	@media ${screen("lg")} {
		opacity: 1;
	}
`;

const SmallPrint = styled.span`
	${meta}
	${spacingRestricted("margin-bottom", "m")}
	display: inline-block;
	text-align: center;
	width: 100%;
	opacity: 0.6;

	@media ${screen("md")} {
		${pSmall};
	}
`;

const CtaButton = styled(Button)`
	display: block;
	width: 100%;
	max-width: 500px;
	${button};
	margin-left: auto;
	margin-right: auto;

	@media ${screen("lg")} {
		margin-bottom: 0;
	}
`;

const BGLine = styled.div`
	${gridc(1, 24)}
	height: 2px;
	background-color: ${color("purple")};
	${spacingRestricted("margin-bottom", "ml")}
	${spacingRestricted("margin-top", "ml")}

	@media ${screen("lg")} {
		display: none;
	}
`;

const DisclaimerWrap = styled.div`
	${gridc(3, 44)}

	@media ${screen("md")} {
		${gridc(13, 24)}
	}

	@media ${screen("lg")} {
		${gridc(16, 18)}
	}
`;

const CalculatorBorrowSimple = ({ colorTheme, nextTheme, disclaimer }) => {
	const [data, setData] = useState({ years: 1, amount: 1 });
	const [week, setWeek] = useState({ total: 0, payment: 0 });
	const [month, setMonth] = useState({ total: 0, payment: 0 });
	const mdScreen = useScreenQuery("md");
	const [trackers, setTrackers] = useState({
		years: null,
		amount: null,
	});

	function updateData(prop) {
		return function (val) {
			const dObj = Object.assign({}, data);
			dObj[prop] = val;
			setData(dObj);
			trackers[prop]?.fire(val);
		};
	}

	function handleCtaClick() {
		window.dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: "submit_form",
			event_info: {
				event_category: "Forms",
				event_action: "Form submitted - Personal Loan",
				event_label: "What would you like to borrow? | submitted",
			},
		});
	}

	useEffect(() => {
		setTrackers({
			years: new Tracker(
				"Form field changed - Personal Loan",
				"What would you like to borrow? | years",
				5
			),
			amount: new Tracker(
				"Form field changed - Personal Loan",
				"What would you like to borrow? | amount",
				20000
			),
		});

		window.dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: "load_form",
			event_info: {
				event_category: "Forms",
				event_action: "Form loaded - Personal Loan",
				event_label: "What would you like to borrow? | loaded",
			},
		});
	}, []);

	useEffect(() => {
		const { amount, years } = data;

		const monthly = calc(amount, years, 12);
		const weekly = calc(amount, years, 52);

		setMonth(monthly);
		setWeek(weekly);
	}, [data]);

	function calc(amount, years, yearDivider) {
		const I = 0.1495;
		const payment =
			((I / yearDivider) * amount) /
			(1 - Math.pow(1 + I / yearDivider, years * -yearDivider));
		const totalLoanIntererst = payment * (years * yearDivider) - amount;
		const total = amount + totalLoanIntererst;
		// const mortgageLoanBalance = totalLoan - partPayment

		const formatTotal = formatValue({
			value: `${Math.round(total)}`,
			groupSeparator: ",",
			decimalSeparator: ".",
			prefix: "",
		});

		const formatPayment = formatValue({
			value: `${Math.round(payment)}`,
			groupSeparator: ",",
			decimalSeparator: ".",
			prefix: "",
		});

		return { total: formatTotal, payment: formatPayment };
	}

	return (
		<SectionEl colorTheme={colorTheme}>
			<ContainerEl>
				<ContentWrap>
					<MainHeading as="h2">Loan Repayment Calculator</MainHeading>
					<CalculatorWrap colorTheme={colorTheme}>
						<Calculator>
							<LoanAmountSlider updateData={updateData("amount")} />
							<Spacing />
							<HowLongSlider updateData={updateData("years")} />
						</Calculator>
						<BGLine />
						<Result>
							<H6>ESTIMATED REPAYMENTS FROM</H6>

							<ResultWrap>
								<SingleResult>
									<Value>
										<Dollar>$</Dollar>
										<NumberSpan>{week.payment}</NumberSpan>
										<Period>/wk</Period>
									</Value>

									<ValueTotal>${week.total} total</ValueTotal>
								</SingleResult>

								<SingleResult>
									<Value>
										<Dollar>$</Dollar>
										<NumberSpan>{month.payment}</NumberSpan>
										<Period>/mth</Period>
									</Value>

									<ValueTotal>${month.total} total</ValueTotal>
								</SingleResult>
							</ResultWrap>

							<SmallPrint>
								Calculated at an example rate of 14.95% p.a.² Not an offer of finance.
							</SmallPrint>

							<CtaButton
								url="https://apply.thelendingpeople.co.nz/loan-type"
								external={true}
								target="_blank"
								onClick={handleCtaClick}
								aria-label="Apply Now"
							>
								APPLY NOW
							</CtaButton>
						</Result>
					</CalculatorWrap>
				</ContentWrap>
			</ContainerEl>
		</SectionEl>
	);
};

export default CalculatorBorrowSimple;
